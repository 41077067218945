import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { MALLS } from 'libs/shared/src/lib/utils/constants';
import { MallModel } from 'libs/shared/src/lib/models/utils/mall.model';

@Component({
  selector: 'app-malls',
  templateUrl: './malls.component.html',
  styleUrls: ['./malls.component.scss'],
})
export class MallsComponent {
  public malls: Array<MallModel> = MALLS.filter((mall) => mall.active);

  constructor(private readonly router: Router) {}

  public goToMall(mallId: number): void {
    this.router.navigate([`/external/${mallId}`]);
  }
}
