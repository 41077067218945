<main id="malls-ticket">
  <section>
    <nz-row>
      <nz-col nzSpan="24">
        <h1 class="title no-margin-bottom">Em qual empreendimento você está?</h1>
      </nz-col>
    </nz-row>

    <nz-divider></nz-divider>

    <nz-row>
      <nz-col nzSpan="24">
        <nz-list nzItemLayout="horizontal">
          <nz-list-item *ngFor="let mall of malls" class="mall-card" (click)="goToMall(mall.id)">
            <nz-row class="w-100">
              <nz-col nzSpan="24">
                <span class="mall-name">{{ mall.name }}</span>
              </nz-col>
            </nz-row>
            <nz-row class="w-100">
              <nz-col nzSpan="24">
                <span class="mall-address">{{ mall.address }}</span>
              </nz-col>
            </nz-row>
          </nz-list-item>
        </nz-list>
      </nz-col>
    </nz-row>
  </section>
</main>
